<template>
  <base-layout>
    <router-view />
  </base-layout>
</template>

<script>

import BaseLayout from '@/layouts/BaseLayout'

export default {
  components: {
    BaseLayout
  }
}
</script>

<style>

</style>
